import * as firebase from 'firebase/app'

import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'

const config = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID,
    measurementId: process.env.FIREBASE_MEASUREMENT_ID
}

const app = firebase.initializeApp(config)

export default app

// if (process.env.IS_LOCAL) {
//     console.log(process.env.FIREBASE_AUTH_DOMAIN)
//     app.auth().useEmulator(process.env.FIREBASE_AUTH_DOMAIN)
//     app.firestore().useEmulator(
//         process.env.FIREBASE_FIRESTORE_HOST,
//         Number(process.env.FIREBASE_FIRESTORE_PORT)
//     )
// }
