import * as React from 'react'
import { ethers } from 'ethers'
import * as Contract from '~/modules/contract'

declare global {
    interface Window {
        ethereum: any | undefined // metamask injects global ethereum
    }
}

type Provider = ethers.providers.Web3Provider
type Network = ethers.providers.Network
type Props = {
    children: React.ReactNode
}
type MetaMaskContextType = {
    connect: () => Promise<void>
    connected: boolean
    provider: Provider | null
    signer: ethers.Signer | null
    network: Network | null
    address: string | null
    contract: Contract.Mock | null
}

export const MetaMaskContext = React.createContext<MetaMaskContextType>({
    connect: async () => {},
    provider: null,
    signer: null,
    network: null,
    address: null,
    connected: false,
    contract: null
})

const ADDRESS = '0x6144d927ee371de7e7f8221b596f3432e7a8e6d9'

export const MetaMaskProvider = ({ children }: Props) => {
    const [connected, setConnected] = React.useState<boolean>(false)
    const [provider, setProvider] = React.useState<Provider | null>(null)
    const [signer, setSigner] = React.useState<ethers.Signer | null>(null)
    const [network, setNetwork] = React.useState<Network | null>(null)
    const [address, setAddress] = React.useState<string | null>(null)
    const [contract, setContract] = React.useState<Contract.Mock | null>(null)

    const connect = async () => {
        try {
            await attemptConnection()
        } catch (error) {
            console.error(error)
            alert((error as Error).message)
        }
    }

    const attemptConnection = async () => {
        if (window.ethereum === undefined) {
            if (window.confirm('Open in the MetaMask app?')) {
                const baseUrl = 'https://metamask.app.link/dapp/'
                location.href = `${baseUrl}${location.hostname}${location.pathname}${location.search}`
                return
            }
            alert('MetaMask should be installed.')
            return
        }
        // make sure page refreshes when network is changed
        // https://github.com/MetaMask/metamask-extension/issues/8226
        window.ethereum.on('chainIdChanged', () => window.location.reload())
        window.ethereum.on('chainChanged', () => window.location.reload())
        window.ethereum.on('accountsChanged', () => window.location.reload())

        // get provider, address, and network
        const eProvider = new ethers.providers.Web3Provider(window.ethereum)
        await eProvider.send('eth_requestAccounts', [])
        const eSigner = await eProvider.getSigner()
        const address = await eSigner.getAddress()
        const eNetwork = await eProvider.getNetwork()
        const contract = await Contract.Mock__factory.connect(ADDRESS, eSigner)

        // set states
        setAddress(address)
        setProvider(eProvider)
        setNetwork(eNetwork)
        setSigner(eSigner)
        setConnected(true)
        setContract(contract)
    }

    React.useEffect(() => {
        if (window.ethereum) {
            window.ethereum
                .request({
                    method: 'eth_accounts'
                })
                .then((r: Array<any>) => {
                    if (r.length > 0) return connect()
                    return
                })
        }
    }, [])

    return (
        <MetaMaskContext.Provider
            value={{
                address,
                network,
                provider,
                signer,
                connected,
                connect,
                contract
            }}
        >
            {children}
        </MetaMaskContext.Provider>
    )
}
