import * as React from 'react'
import * as reactRedux from 'react-redux'
import * as ethers from 'ethers'
import * as MainTemplate from '~/templates/Main'
import * as SignInTemplate from '~/templates/SignIn'
import { MetaMaskContext } from '~/provider/MetaMask'
import axios from 'axios'
import * as ToastActions from '~/store/ui/toast'
import { SelectChangeEvent } from '@mui/material'

const apiClient = axios.create({
    baseURL: 'https://tick3t-api-v2-sl6bqje65q-uw.a.run.app/',
    headers: {
        Authorization:
            'Bearer 9b2337fee411655e5aa46163ead9477b8a91cb5e54f85b9b298205196983d1b1'
    }
})

export const Component = () => {
    const dispatch = reactRedux.useDispatch()
    const { connected, signer, address, connect } =
        React.useContext(MetaMaskContext)

    const [loading, setLoading] = React.useState<boolean>(false)
    const [tokenId, setTokenId] = React.useState<string>('')
    const [qr, setQr] = React.useState<string>('')
    const [blueBirds, setBlueBirds] = React.useState([])

    React.useEffect(() => {
        if (!address) return
        apiClient
            .get('/nft', {
                params: {
                    accountAddress: address,
                    contractAddress:
                        '0x778efc5961570158df0989ad7c9369ca23383adc'
                }
            })
            .then(r => {
                const { data } = r
                setBlueBirds(
                    data.ownedNfts.map(
                        (r: {
                            id: { tokenId: string }
                            metadata: { name: string; image: string }
                        }) => {
                            return {
                                value: ethers.BigNumber.from(
                                    r.id.tokenId
                                ).toString(),
                                name: r.metadata.name,
                                image: r.metadata.image
                            }
                        }
                    )
                )
            })
    }, [address])

    const onClickSubmit = React.useCallback(async () => {
        if (!tokenId || !signer) return
        try {
            setLoading(true)
            const address = await signer.getAddress()
            let { data } = await apiClient.get('/nonce', {
                params: {
                    address
                }
            })

            const messageData = {
                issuer: 'JRtlKAljmbWD0BX9V7ZF',
                event_id: 'demo-bluebirds',
                nonce: data.nonce,
                nft: {
                    contract_address:
                        '0x778efc5961570158df0989ad7c9369ca23383adc',
                    token_id: tokenId
                }
            }

            const message = JSON.stringify(messageData)

            const signature = await signer.signMessage(message)

            data = (
                await apiClient.post('/issue', {
                    signature,
                    message: messageData
                })
            ).data

            console.log(JSON.stringify(data))

            setLoading(false)
            setQr(JSON.stringify(data))

            data = (await apiClient.post('/verify', data)).data
            console.log(data)
        } catch (err) {
            setLoading(false)
            console.log(err)
            dispatch(
                ToastActions.append({
                    variant: 'error',
                    label: 'Submit Error',
                    text: err?.message || 'Hmm....'
                })
            )
        }
    }, [dispatch, tokenId, signer])

    const onChangeTokenId = (event: SelectChangeEvent<string>) => {
        setTokenId(event.target.value)
    }

    return React.useMemo(() => {
        if (!connected) {
            return <SignInTemplate.Component onClickConnect={connect} />
        } else {
            return (
                <MainTemplate.Component
                    address={address}
                    tokenId={tokenId}
                    qr={qr}
                    onSubmit={onClickSubmit}
                    loading={loading || false}
                    onChangeTokenId={onChangeTokenId}
                    blueBirds={blueBirds}
                />
            )
        }
    }, [
        connected,
        connect,
        address,
        tokenId,
        qr,
        onClickSubmit,
        loading,
        blueBirds
    ])
}
